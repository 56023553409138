import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout/layout"
import favicon from "../images/favicon.ico"

import GetStartedSteps from "../components/GetStartedSteps"
import TestimonialCarousel from "../components/TestimonialCarousel"

import heroImg from "../images/marketing/svg/home/homepage-hero-img.svg"
import compareImg from "../images/marketing/svg/home/introduction/compare.svg"
import checkImg from "../images/marketing/svg/home/introduction/check.svg"
import walletImg from "../images/marketing/svg/home/introduction/wallet.svg"
import anda from "../images/marketing/ws-logos/anda.png"
import capital from "../images/marketing/ws-logos/capital.png"
import citymed from "../images/marketing/ws-logos/citymed.png"
import integral from "../images/marketing/ws-logos/integral.png"
import keysource from "../images/marketing/ws-logos/keysource.png"
import rivercity from "../images/marketing/ws-logos/rivercity.png"
import blupax from "../images/marketing/ws-logos/blupax.png"
import dynasty from "../images/marketing/ws-logos/dynasty.png"
import oak_drugs from "../images/marketing/ws-logos/oak_drugs.png"
import real_value from "../images/marketing/ws-logos/real_value.png"
import qualitycare from "../images/marketing/ws-logos/qualitycare.svg"
import hercules from "../images/marketing/ws-logos/hercules.png"
import ndc_distributors from "../images/marketing/ws-logos/ndc_distributors.png"
import second_source from "../images/marketing/ws-logos/second_source.png"
import south_pointe from "../images/marketing/ws-logos/south_pointe.png"
import homepageImage from "../images/marketing/svg/home/dashboard-img.png"
import gpw from "../images/marketing/ws-logos/gpw.png"
import republic from "../images/marketing/ws-logos/republic_pharma.png"
import pharmsource from "../images/marketing/ws-logos/pharmsource.png"
import astor from "../images/marketing/ws-logos/astor.png"
import parmed from "../images/marketing/ws-logos/parmed.png"

export default () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          EzriRx.com - Pharmacy Marketplace - Pharmacy Platform - 65,000+ Rx
          Pharmacy Items
        </title>
        <meta name="keywords" content="ezrirx, pharmacy purchasing platform" />
        <meta
          name="description"
          content="EzriRx's pharmacy marketplace allows pharmacies to compare drug prices offered by many suppliers and purchase from the most favorable. Browse, compare, and save an average of 30% on all drugs."
        />
        <meta name="facebook-domain-verification" content="rta53j7pt3gdn7ryh7637jluafyg2i" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://s3.amazonaws.com/ezrirx-assets/adcards/EzriRx_og.png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:site_name" content="EzriRx.com" />
        <meta property="og:title" content="EzriRx.com - Pharmacy & Wholesalers Marketplace - One Platform | Multiple Wholesalers | Amazing Savings" />
        <meta property="og:description" content="EzriRx's pharmacy marketplace allows pharmacies to compare drug prices offered by many suppliers and purchase from the most favorable. Browse, compare, and save an average of 30% on all drugs." />
        <meta property="og:url" content="https://www.ezrirx.com/" />
        <link rel="canonical" href="https://www.ezrirx.com/" />
        <link rel="shortcut icon" type="image/png" href={`${favicon}`} />
      </Helmet>
      <Layout>
        <div className="body">
          <section className="hero-banner" id="hero-home">
            <div className="contents">
              <div className="hero-banner__inner">
                <div className="hero-content">
                  <div className="hero-content__news">
                    <a rel="noreferrer" href="https://app.ezrirx.com/" className="text-decoration-none" target="_blank">
                      <span className="status">New</span>
                      EzriRx on mobile. Try now.
                    </a>
                  </div>
                  <h1>
                    Our platform is the new standard in healthcare purchasing
                  </h1>
                  <p>
                    Thousands of forward-thinking pharmacies and healthcare
                    providers in the US, use EzriRx to purchase millions of
                    dollars worth of Rx, HBA, OTC, Med Surg, and Pet Meds daily
                    with incredible savings - all in an advanced yet easy-to-use
                    platform.
                  </p>
                  <a className="button move-to-section" href="https://app.ezrirx.com/signup/pharmacy/">
                    Sign Up
                  </a>
                </div>
                <div className="hero-image">
                  <img src={heroImg} alt=" "></img>
                </div>
              </div>
            </div>
          </section>

          <div id="our-features">
            <section className="introduction-wrapper">
              <div className="contents">
                <div className="introduction-box">
                  <a className="legit-script" href="https://legitscript.com" target="_blank" title="Verify LegitScript Approval" rel="noreferrer">
                    <img src="https://static.legitscript.com/seals/2885027.png" alt="LegitScript approved" width="140" height="100" border="0" className="d-block" />
                  </a>
                  <div className="title-box text-center">
                    <h2>
                      Trusted by 5000 Healthcare providers in the US.
                      (Pharmacies, Hospitals, Long Term Care, Physicians, etc.)
                    </h2>
                    <p>
                      As a collective of pharmacy enthusiasts, we developed technology to simplify, manage, and analyze your Rx purchases. Ultimately saving you money! 
                    </p>
                  </div>

                  <div className="box-3cols clearfix">
                    <div className="col col--intro">
                      <img
                        src={compareImg}
                        alt="Pharmacies compare pricing on our Platform"
                      ></img>
                      <div className="col--intro__content">
                        <h4>Review and Analyze</h4>
                        <p>
                          EzriRx allows healthcare providers to procure, manage, and analyze purchases from 
                          suppliers to increase your bottom line. From Rx to OTC, HBA, and Medical Supplies. 
                        </p>
                      </div>
                    </div>
                    <div className="col col--intro">
                      <img
                        src={checkImg}
                        alt="Easy to use Pharmacy Platform"
                      ></img>
                      <div className="col--intro__content">
                        <h4>Easy to use</h4>
                        <p>
                          EzriRx is the easiest way to purchase healthcare
                          products. It takes under two minutes to complete the
                          sign-up process and get started.
                        </p>
                      </div>
                    </div>
                    <div className="col col--intro">
                      <img
                        src={walletImg}
                        alt="Our Pharmacy Portal is free for life"
                      ></img>
                      <div className="col--intro__content">
                        <h4>Free for Pharmacies</h4>
                        <p>
                          EzriRx Basic is free to pharmacies. 
                          Sign up, apply, and purchase within minutes. 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="what-is-ezrirx">
                  <div className="table-2cols">
                    <div className="col">
                       <img
                        src={homepageImage}
                        alt="Create a free account on our Rx Marketplace"
                        width="500"
                      ></img>
                    </div>
                    <div className="col">
                      <div className="title-box">
                        <h2>What is EzriRx?</h2>
                      </div>
                      <p>
                        EzriRx builds the most powerful and flexible tools for pharmaceutical
                        purchasing. Whether you’re an independent, chain, or long-term care pharmacy, 
                        EzriRx’s meticulously-designed platform allows pharmacies to shop on over 55,000 Rx, 
                        OTC, HBA, Med-Surg and Pet Supplies from over 20 wholesalers licensed in your state.
                      </p>
                      <p>
                        By joining a collective of customers, pharmacies can save on negative reimbursements 
                        and ultimately increase their bottom line.
                      </p>
                      <p>
                        But we didn’t stop there. We created online invoice management to track your 
                        balances with each supplier, multiple payment options 
                        to simplify paying invoices, and a slew of features to 
                        enhance a pharmacies everyday performance.
                      </p>
                      <p>
                        It’s one platform, with many wholesalers, and amazing savings!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <section>
          <GetStartedSteps />
        </section>
        <section>
          <TestimonialCarousel />
        </section>
        <section className="wholesaler-logos text-center">
          <div className="contents">
            <div className="wholesaler-logos__list">
              <span className="item">
                <img src={anda} alt="Anda"></img>
              </span>
              <span className="item">
                <img src={capital} alt="Capital Drug"></img>
              </span>
              <span className="item">
                <img src={citymed} alt="City Med"></img>
              </span>
              <span className="item">
                <img src={integral} alt="Integral"></img>
              </span>
              <span className="item">
                <img src={keysource} alt="KeySource"></img>
              </span>
              <span className="item">
                <img src={astor} alt="Astor Drugs"></img>
              </span>
              <span className="item">
                <img src={rivercity} alt="River City Pharma"></img>
              </span>
              <span className="item">
                <img src={blupax} alt="Blupax Pharma"></img>
              </span>
              <span className="item">
                <img src={dynasty} alt="Dynasty Pharmaceuticals"></img>
              </span>
              <span className="item">
                <img src={oak_drugs} alt="Oak Drugs"></img>
              </span>
              <span className="item">
                <img src={real_value} alt="Real Value Rx"></img>
              </span>
              <span className="item">
                <img src={qualitycare} alt="Quality Care Products"></img>
              </span>
              <span className="item">
                <img src={second_source} alt="Second Source Rx"></img>
              </span>
              <span className="item">
                <img src={hercules} alt="Hercules Pharmaceuticals Inc."></img>
              </span>
              <span className="item">
                <img src={ndc_distributors} alt="NDC Distributors"></img>
              </span>
              <span className="item">
                <img src={south_pointe} alt="Southe Pointe"></img>
              </span>
              <span className="item">
                <img src={gpw} alt="GPW"></img>
              </span>
              <span className="item">
                <img src={republic} alt="Republic Pharmaceuticals"></img>
              </span>
              <span className="item">
                <img src={pharmsource} alt="Pharmsource, LLC"></img>
              </span>
              <span className="item">
                <img src={parmed} alt="Parmed Pharmaceuticals"></img>
              </span>
            </div>
            <a
              href="https://app.ezrirx.com/signup/pharmacy/"
              rel="nofollow"
              className="button blue-dark"
            >
              See our wholesalers
            </a>
          </div>
        </section>
      </Layout>
    </div>
  )
}
