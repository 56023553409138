import React from "react"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"

import step1Image from "../images/marketing/svg/home/guide/step-1.svg"
import step3Image from "../images/marketing/svg/home/guide/step-3.svg"
import step4Image from "../images/marketing/svg/home/guide/step-4.svg"
import step2Image from "../images/marketing/svg/home/guide/step-2.svg"

export default () => {
  const handleNextStepClick = stepNum => {
    const elem = document.querySelector(`[data-rb-event-key="step${stepNum}"]`)
    elem.click()
  }

  const stepOneTitle = () => {
    return (
      <>
        <span className="number">1</span>
        <strong>Create a free account</strong>
      </>
    )
  }
  const stepTwoTitle = () => {
    return (
      <>
        <span className="number">2</span>
        <strong>Apply for Wholesalers</strong>
      </>
    )
  }
  const stepThreeTitle = () => {
    return (
      <>
        <span className="number">3</span>
        <strong>Add payment methods</strong>
      </>
    )
  }
  const stepFourTitle = () => {
    return (
      <>
        <span className="number">4</span>
        <strong>Start saving</strong>
      </>
    )
  }
  return (
    <section className="four-simple-steps">
      <div className="contents">
        <div className="title-box text-center">
          <h2>Get started in four simple steps</h2>
        </div>

        <Tabs defaultActiveKey="step1" transition={false}>
          <Tab eventKey="step1" title={stepOneTitle()}>
            <div className="table-2cols align-top">
              <div className="col">
                <div className="title-box">
                  <h2>Ready to get started? Create an account!</h2>
                </div>
                <p>
                  Get started in minutes by providing some basic information
                  about you and your pharmacy. It’s easy and free to create your
                  account.
                </p>

                <div className="actions">
                  <button
                    onClick={() => handleNextStepClick(2)}
                    className="button blue-dark btn-next"
                  >
                    Next step
                  </button>
                </div>
              </div>
              <div className="col">
                <img
                  src={step1Image}
                  alt="Create a free account on our Rx Marketplace"
                ></img>
              </div>
            </div>
          </Tab>
          <Tab eventKey="step2" title={stepTwoTitle()}>
            <div className="table-2cols">
              <div className="col">
                <div className="title-box">
                  <h2>Apply for Wholesalers</h2>
                </div>
                <p>
                  EzriRx uses modern e-signature technology to allow you to
                  provide a single digital signature that we re-use to
                  automatically pre-fill each wholesaler’s customer application,
                  saving you from spending a considerable amount of time filling
                  out and signing forms.
                </p>

                <div className="actions">
                  <button
                    onClick={() => handleNextStepClick(3)}
                    className="button blue-dark btn-next"
                  >
                    Next step
                  </button>
                </div>
              </div>
              <div className="col">
                <img
                  src={step3Image}
                  alt="Apply for Pharmacy Wholesalers"
                ></img>
              </div>
            </div>
          </Tab>
          <Tab eventKey="step3" title={stepThreeTitle()}>
            <div className="table-2cols">
              <div className="col">
                <div className="title-box">
                  <h2>Add Payment Methods</h2>
                </div>
                <p>
                  Add credit cards and bank accounts (ACH) on the payment
                  settings page. You’ll pay for orders directly on EzriRx safely
                  and securely using your preferred payment method.
                </p>

                <div className="actions">
                  <button
                    onClick={() => handleNextStepClick(4)}
                    className="button blue-dark btn-next"
                  >
                    Next step
                  </button>
                </div>
              </div>
              <div className="col">
                <img src={step4Image} alt="Add payment method"></img>
              </div>
            </div>
          </Tab>
          <Tab eventKey="step4" title={stepFourTitle()}>
            <div className="table-2cols">
              <div className="col">
                <div className="title-box">
                  <h2>Start Saving!</h2>
                </div>
                <p>
                  You’re ready to go! Start shopping and enjoy real savings on
                  pharmaceuticals.
                </p>

                <div className="actions">
                  <a
                    href="https://app.ezrirx.com/signup/pharmacy/"
                    rel="nofollow"
                    className="button blue-dark"
                  >
                    Join EzriRx
                  </a>
                </div>
              </div>
              <div className="col">
                <img
                  src={step2Image}
                  alt="Start Saving on Pharmaceuticals"
                ></img>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </section>
  )
}
