import React from "react"

import SuburbanStore from "../images/testimonials/SuburbanStore.jpg"
import NickShanos from "../images/testimonials/NickShanos.png"
import LevyPharmacy from "../images/testimonials/levys-store.jpg"
import ConleysPharmacy from "../images/testimonials/conleys-store.jpg"
import MarinoPharmacy from "../images/testimonials/marino-store.jpg"
import SuperHealthPharmacy from "../images/testimonials/SuperHealthpharmacy.jpg"
import SuperHealthPharmacyPerson from "../images/testimonials/SuperHealthPharmacyperson.jpg"
import SmartRxPharmacy from "../images/testimonials/smart-rx-store.png"
import SmartRxPharmacist from "../images/testimonials/smart-rx-pharmacist.jpg"
import NoPicture from "../images/testimonials/NoPicture.svg"

export default () => {
  return (
    <div className="testimonial-box">
      <h2>What pharmacists are saying</h2>

      <div className="testimonial-cards">
        <div className="testimonial-item row-eq-height">
          <div className="store-image">
            <img src={SuburbanStore} alt="Suburban Pharmacy"></img>
          </div>
          <div className="quotes"></div>
          <div className="testimonial-cards__content">
            <p>
              EzriRx is fantastic!! Extremely intuitive and logically organized
              as well as a progressive company where customers come first.
              EzriRx has streamlined our processing by uploading order files
              from QS1. All Bills/Invoices and Payments are in one central
              location so there is no need to search individual wholesaler
              websites to find invoice history. I just love this website! Very
              responsive to inquiries, and any questions I have are answered
              quickly and competently.
            </p>
          </div>
          <div className="testimonial-footer">
            <div className="person-image">
              <img src={NickShanos} alt="Nick Shanos"></img>
            </div>
            <div className="name">
              Nick Shanos<p>Suburban Pharmacy</p>
            </div>
          </div>
        </div>
        <div className="testimonial-item row-eq-height">
          <div className="store-image">
            <img src={ConleysPharmacy} alt="Conleys Drug store"></img>
          </div>
          <div className="quotes"></div>
          <div className="testimonial-cards__content ">
            <p>
              Since its inception, EzriRx has been dedicated to saving
              pharmacies the most money while maintaining an easy to use
              platform. The wholesalers behind EzriRx are vetted carefully and
              are reliable. It changed our store from using a single source
              vendor to shopping from many & ultimately getting the biggest
              savings. To date, I can confidently say, that EzriRx has impacted
              my bottom-line margins tremendously and I can never be more
              thankful.
            </p>
          </div>
          <div className="testimonial-footer">
            <div className="person-image">
              <img src={NoPicture} alt="Alex Doyle"></img>
            </div>
            <div className="name">
              Alex Doyle<p>Conley's Drug Store </p>
            </div>
          </div>
        </div>
        <div className="testimonial-item row-eq-height">
          <div className="store-image">
            <img src={SmartRxPharmacy} alt="Smart Rx Pharmacy"></img>
          </div>
          <div className="quotes"></div>
          <div className="testimonial-cards__content ">
            <p>
              EzriRx has saved my pharmacy time and money. Pedigree, Invoices
              and Clutter are a thing of the past. With the velocity and power
              of technology, EzriRx has changed how we shop for our pharmacy,
              haggle with wholesalers, pay our bills and manage our
              documentation. EzriRx realized our pain points and created a
              seamless solution to benefit our operations. The staff behind
              EzriRx are gems.
            </p>
          </div>
          <div className="testimonial-footer">
            <div className="person-image">
              <img src={SmartRxPharmacist} alt="Yaakov Mavashev"></img>
            </div>
            <div className="name">
              Yakov Mavashev<p>SmartRx</p>
            </div>
          </div>
        </div>
        <div className="testimonial-item row-eq-height">
          <div className="store-image">
            <img src={LevyPharmacy} alt="Levy's Pharmacy"></img>
          </div>
          <div className="quotes"></div>
          <div className="testimonial-cards__content ">
            <p>
              The marriage between Pharmacy and Technology has been a long time
              coming. Transitioning from Fax ordering to a simplistic platform
              that handles everything has changed our daily processes and
              bottom-line. From sign-up to placing our first orders, the process
              took us under a day and EzriRx has maintained that pace for every
              step of the way, since. It is a breeze to use and has shifted our
              business dynamics.
            </p>
          </div>
          <div className="testimonial-footer">
            <div className="person-image">
              <img src={NoPicture} alt="M Badalov"></img>
            </div>
            <div className="name">
              M Badalov<p>Levys Pharmacy</p>
            </div>
          </div>
        </div>
        <div className="testimonial-item row-eq-height">
          <div className="store-image">
            <img src={SuperHealthPharmacy} alt="pharmacy name"></img>
          </div>
          <div className="quotes"></div>
          <div className="testimonial-cards__content ">
            <p>
              5-Stars for a service well-done. You have saved us time, money,
              and are crushing it on low-reimbursement items. We went from
              negative margins on many items from our primary to making money on
              these scripts. We forever wonder why every single pharmacy in the
              US does not use EzriRx. It has been a long time coming for such a
              service to exist and it gets better every day. Thank you, thank
              you!
            </p>
          </div>
          <div className="testimonial-footer">
            <div className="person-image">
              <img src={SuperHealthPharmacyPerson} alt="Mayank Parikh"></img>
            </div>
            <div className="name">
              Mayank Parikh<p>Super Health Pharmacy</p>
            </div>
          </div>
        </div>
        <div className="testimonial-item row-eq-height">
          <div className="store-image">
            <img src={MarinoPharmacy} alt="Marino Pharmacy"></img>
          </div>
          <div className="quotes"></div>
          <div className="testimonial-cards__content ">
            <p>
              Rather than getting inundated with calls, reps, emails, and faxes
              from every supplier, we switched to EzriRx. One-stop platform that 
              has everything we need in one place.. Brands,
              Generics, OTC, HBA, Medical Supplies and Pet Meds. Its simple UX
              allows our Techs to do the shopping and it saves an enormous
              amount of time and money. Our pharmacy has been using EzriRx since
              2018 and there is no going back.
            </p>
          </div>
          <div className="testimonial-footer">
            <div className="person-image">
              <img src={NoPicture} alt="Robert A"></img>
            </div>
            <div className="name">
              Robert A<p>Marino Pharmacy</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
